
::v-deep.el-breadcrumb{
    .el-breadcrumb__item{
        font-size: 14px;
        font-weight: 400;
        color: #5F5C74;
        .is-link{
            font-weight: 400;
            color: #BCB9CB;
        }
    }
}
.notice-box{
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    .notice-content{
        min-height: calc(100vh - 260px);
        background: #fff;
        margin-top: 15px;
        box-shadow: 0px 0px 17px 1px rgba(11, 12, 28, 0.09);
        border-radius: 10px;
        padding: 42px 36px 90px 0px;
        display: flex;
        flex-direction: column;
        .layui-timeline-item {
            margin-left: 170px;
            position: relative;
            line-height: 1;
            display: flex;
            flex-direction: column;
            .triangle-left{
                height:0;
                width:0;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-right: 9px solid #EFEEFF;
                position: absolute;
                left: 12px;
                top: 15px;
            }
        }
        .layui-timeline-item:first-child::before {
            display: block;
        }

        /*.layui-timeline-item:last-child::before {*/
        /*    content: '';*/
        /*    position: absolute;*/
        /*    left: 5px;*/
        /*    top: 0;*/
        /*    z-index: 0;*/
        /*    width: 0;*/

        /*}*/

        .layui-timeline-item::before {
            content: '';
            position: absolute;
             /*left: 0px;*/
            top: 0;
            z-index: 0;
            width: 3px;
            height: 100%;
        }

        .layui-timeline-item::before,
        hr {
            background-color: #564CED;
        }

        .layui-timeline-axis {
            position: absolute;
            left: -2.5px;
            top: 18px;
            z-index: 10;
            width: 20px;
            height: 20px;
            line-height: 20px;
            background-color: #fff;
            border-radius: 50%;
            text-align: center;
            cursor: pointer;
        }

        .layui-icon {
            font-family: layui-icon !important;
            font-size: 16px;
            font-style: normal;
        }

        .layui-timeline-content {
            padding:12px 25px;
            line-height: 1;
            display: flex;
            flex-direction: column;
            background: #EFEEFF;
            border-radius: 4px;
            margin-left: 20px;
            margin-bottom: 16px;
            span{
                color: #999999;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .layui-text {
            font-size: 14px;
            color: #666;
            top:-10px;
        }

        .layui-timeline-title {
            position: relative;
            margin-bottom: 10px;
            color: #3A3567!important;
            font-size: 16px;
        }

        .circle {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #564CED;
        }

        .etime-first {
            top: 45%;
            left: -100px;
            position: absolute;
        }

        .time-first {
            position: absolute;
            left: -144px;
            top: 16px;
            font-size: 14px;
            color: #5E53F0;
            font-weight: 400;
        }
    }
    .notice-btn{
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        margin-top: 30px;
        bottom: 30px;
        .btn{
            width: 100px;
            height: 40px;
            padding: 0;
            color: #fff;
            background: #564CED;
            border-color: #564CED;
        }
    }
}
.noData{
    height: calc(100vh - 170px);
    margin-top: 15px;
    background: #fff;
    line-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 17px 1px rgba(11, 12, 28, 0.09);
    border-radius: 10px;
    padding: 42px 36px 0 0px;
}

